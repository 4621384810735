// export const SET_DATA = (state, newData) => {
//   state.dataUserLogin = newData;
// }

const mutations = {
  // Definisikan mutations di sini
  SET_DATA(state, newData) {
    state.data = newData;
  },

  AboutDashboard(state, AboutDashboard) {
    state.AboutDashboard = AboutDashboard;
  },
  
  ProfileAccount(state, ProfileAccount) {
    state.ProfileAccount = ProfileAccount;
  },

  ServiceTalent(state, ServiceTalent) {
    state.ServiceTalent = ServiceTalent;
  },

  postServiceTalent(state, postServiceTalent) {
    state.postServiceTalent = postServiceTalent;
  },

  // product
  productData(state, productData) {
    state.productData = productData;
  },

  // member
  memberData(state, memberData) {
    state.memberData = memberData;
  },

  memberStample(state, memberStample) {
    state.memberStample = memberStample;
  },

  memberStampleClaim(state, memberStampleClaim) {
    state.memberStampleClaim = memberStampleClaim;
  },

  memberPurchasing(state, memberPurchasing) {
    state.memberPurchasing = memberPurchasing;
  },

  memberCheck(state, memberCheck) {
    state.memberCheck = memberCheck;
  },

  // membuat award data
  memberAward(state, memberAward) {
    state.memberAward = memberAward;
  },

  memberAwardRecord(state, memberAwardRecord) {
    state.memberAwardRecord = memberAwardRecord;
  },
  
  // membuat data
  dataBarang(state, dataBarang) {
    state.dataBarang = dataBarang;
  },

  storeBarang(state, storeBarang) {
    state.storeBarang = storeBarang;
  },

  showBarang(state, showBarang) {
    state.showBarang = showBarang;
  },

  updateBarang(state, updateBarang) {
    state.updateBarang = updateBarang;
  },

  deleteBarang(state, deleteBarang) {
    state.deleteBarang = deleteBarang;
  },
  // harga modal barang
  indexHargaBarangModal(state, indexHargaBarangModal) {
    state.indexHargaBarangModal = indexHargaBarangModal;
  },

  storeHargaBarangModal(state, storeHargaBarangModal) {
    state.storeHargaBarangModal = storeHargaBarangModal;
  },

  showHargaBarangModal(state, showHargaBarangModal) {
    state.showHargaBarangModal = showHargaBarangModal;
  },

  updateHargaBarangModal(state, updateHargaBarangModal) {
    state.updateHargaBarangModal = updateHargaBarangModal;
  },

  deleteHargaBarangModal(state, deleteHargaBarangModal) {
    state.deleteHargaBarangModal = deleteHargaBarangModal;
  },
  
  // barang satuan
  indexBarangSatuan(state, indexBarangSatuan) {
    state.indexBarangSatuan = indexBarangSatuan;
  },
  storeBarangSatuan(state, storeBarangSatuan) {
    state.storeBarangSatuan = storeBarangSatuan;
  },
  showBarangSatuan(state, showBarangSatuan) {
    state.showBarangSatuan = showBarangSatuan;
  },
  updateBarangSatuan(state, updateBarangSatuan) {
    state.updateBarangSatuan = updateBarangSatuan;
  },
  deleteBarangSatuan(state, deleteBarangSatuan) {
    state.deleteBarangSatuan = deleteBarangSatuan;
  },

  // barang master
  indexBarangMaster(state, indexBarangMaster) {
    state.indexBarangMaster = indexBarangMaster;
  },
  storeBarangMaster(state, storeBarangMaster) {
    state.storeBarangMaster = storeBarangMaster;
  },
  showBarangMaster(state, showBarangMaster) {
    state.showBarangMaster = showBarangMaster;
  },
  updateBarangMaster(state, updateBarangMaster) {
    state.updateBarangMaster = updateBarangMaster;
  },
  deleteBarangMaster(state, deleteBarangMaster) {
    state.deleteBarangMaster = deleteBarangMaster;
  },
  // barang harga
  indexBarangHarga(state, indexBarangHarga) {
    state.indexBarangHarga = indexBarangHarga;
  },

  // filter barang
  indexFilterBarang(state, indexFilterBarang) {
    state.indexFilterBarang = indexFilterBarang;
  },

  // temporder
  indexTempOrder(state, indexTempOrder) {
    state.indexTempOrder = indexTempOrder;
  },
  storeTempOrder(state, storeTempOrder) {
    state.storeTempOrder = storeTempOrder;
  },
  deleteTempOrder(state, deleteTempOrder) {
    state.deleteTempOrder = deleteTempOrder;
  },

  // order
  indexOrder(state, indexOrder) {
    state.indexOrder = indexOrder;
  },
  storeOrder(state, storeOrder) {
    state.storeOrder = storeOrder;
  },

  // order detail
  indexOrderDetail(state, indexOrderDetail) {
    state.indexOrderDetail = indexOrderDetail;
  },

  // pelanggan
  indexPelanggan(state, indexPelanggan) {
    state.indexPelanggan = indexPelanggan;
  },
  storePelanggan(state, storePelanggan) {
    state.storePelanggan = storePelanggan;
  },
  showPelanggan(state, showPelanggan) {
    state.showPelanggan = showPelanggan;
  },
  updatePelanggan(state, updatePelanggan) {
    state.updatePelanggan = updatePelanggan;
  },
  deletePelanggan(state, deletePelanggan) {
    state.deletePelanggan = deletePelanggan;
  },

  // pembayaran
  indexPembayaran(state, indexPembayaran) {
    state.indexPembayaran = indexPembayaran;
  },

  // metode-pembayaran
  indexMetodePembayaran(state, indexMetodePembayaran) {
    state.indexMetodePembayaran = indexMetodePembayaran;
  },

  // V.1.1.4

  indexBarangStok(state, indexBarangStok) {
    state.indexBarangStok = indexBarangStok;
  },
  storeBarangStok(state, storeBarangStok) {
    state.storeBarangStok = storeBarangStok;
  },
  showBarangStok(state, showBarangStok) {
    state.showBarangStok = showBarangStok;
  },
  updateBarangStok(state, updateBarangStok) {
    state.updateBarangStok = updateBarangStok;
  },
  deleteBarangStok(state, deleteBarangStok) {
    state.deleteBarangStok = deleteBarangStok;
  },

  indexLogBarangStok(state, indexLogBarangStok) {
    state.indexLogBarangStok = indexLogBarangStok;
  },

  indexPengiriman(state, indexPengiriman) {
    state.indexPengiriman = indexPengiriman;
  },

  indexSupplier(state, indexSupplier) {
    state.indexSupplier = indexSupplier;
  },
  storeSupplier(state, storeSupplier) {
    state.storeSupplier = storeSupplier;
  },
  showSupplier(state, showSupplier) {
    state.showSupplier = showSupplier;
  },
  updateSupplier(state, updateSupplier) {
    state.updateSupplier = updateSupplier;
  },
  deleteSupplier(state, deleteSupplier) {
    state.deleteSupplier = deleteSupplier;
  },

  // barang gambar
  indexBarangGambar(state, indexBarangGambar) {
    state.indexBarangGambar = indexBarangGambar;
  },
  storeBarangGambar(state, storeBarangGambar) {
    state.storeBarangGambar = storeBarangGambar;
  },
  showBarangGambar(state, showBarangGambar) {
    state.showBarangGambar = showBarangGambar;
  },
  updateBarangGambar(state, updateBarangGambar) {
    state.updateBarangGambar = updateBarangGambar;
  },
  deleteBarangGambar(state, deleteBarangGambar) {
    state.deleteBarangGambar = deleteBarangGambar;
  },

  // login
  loginAuth(state, loginAuth) {
    state.loginAuth = loginAuth;
  },

  logoutAuth(state, logoutAuth) {
    state.logoutAuth = logoutAuth;
  },

  // coming soon
};

export default mutations;