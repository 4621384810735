const state = {
  data: [],
  // dashboard
  AboutDashboard: [],
  ProfileAccount: [],
  ServiceTalent: [],
  postServiceTalent: [],
  // product
  productData: [],
  // member
  memberData: [],
  memberStample: [],
  memberStampleClaim: [],
  memberPurchasing: [],
  memberCheck: [],
  // member award
  memberAward: [],
  memberAwardRecord: [],
  // barang
  dataBarang: {
    data: [],
  },
  storeBarang: [],
  showBarang: {
    data: [],
  },
  updateBarang: [],
  deleteBarang: [],
  // harga barang modal
  indexHargaBarangModal: {
    data: [],
  },
  storeHargaBarangModal: [],
  showHargaBarangModal: {
    data: [],
  },
  updateHargaBarangModal: [],
  deleteHargaBarangModal: [],
  // barang satuan
  indexBarangSatuan: {
    data: [],
  },
  storeBarangSatuan: [],
  showBarangSatuan: {
    data: [],
  },
  updateBarangSatuan: [],
  deleteBarangSatuan: [],
  
  // barang master
  indexBarangMaster: {
    data: [],
  },
  storeBarangMaster: [],
  showBarangMaster: {
    data: [],
  },
  updateBarangMaster: [],
  deleteBarangMaster: [],

  // barang gambar
  indexBarangGambar: {
    data: [],
  },
  storeBarangGambar: [],
  showBarangGambar: {
    data: [],
  },
  updateBarangGambar: [],
  deleteBarangGambar: [],
  
  // barang harga
  indexBarangHarga: {
    data: [],
  },
  storeBarangHarga: [],
  showBarangHarga: {
    data: [],
  },
  
  // filter barang
  indexFilterBarang: {
    data: [],
  },
  
  // temp order
  indexTempOrder: {
    data: [],
  },
  storeTempOrder: [],
  deleteTempOrder: [],
  // order
  indexOrder: {
    data: [],
  },
  storeOrder: [],

  // order detail
  indexOrderDetail: {
    data: [],
  },

  // pelanggan
  indexPelanggan: {
    data: [],
  },
  storePelanggan: [],
  showPelanggan: {
    data: [],
  },
  updatePelanggan: [],
  deletePelanggan: [],

  // pembayaran
  indexPembayaran: {
    data: [],
  },

  // metode-pembayaran 
  indexMetodePembayaran: {
    data: [],
  },

  // V.1.1.4

  // barang-stok
  indexBarangStok: {
    data: [],
  },
  storeBarangStok: [],
  showBarangStok: {
    data: [],
  },
  updateBarangStok: [],
  deleteBarangStok: [],

  // log-barang-stok
  indexLogBarangStok: {
    data: [],
  },

  // pengiriman
  indexPengiriman: {
    data: [],
  },

  // supplier
  indexSupplier: {
    data: [],
  },
  storeSupplier: [],
  showSupplier: {
    data: [],
  },
  updateSupplier: [],
  deleteSupplier: [],
  

  // auth
  loginAuth: [],
  logoutAuth: [],
  
  // coming soon
};

export default state;