<template>
  <div id="app">
    <!-- <HeaderMenu></HeaderMenu> -->
    <div class="container-fluid p-0 d-flex h-100">
      <div v-if="isLoggedIn" id="bdSidebar" style="width: 200px !important;"
        class="d-flex flex-column flex-shrink-0 text-white offcanvas-md offcanvas-start">
        <AsideMenu></AsideMenu>
      </div>

      <div class="bg-light flex-fill">
        <!-- <div class="p-2 d-md-none d-flex text-white bg-primary">
          <a href="#" class="text-white" data-bs-toggle="offcanvas" data-bs-target="#bdSidebar">
            <i class="fa-solid fa-bars"></i>
          </a>
          <span class="ms-3">Toko Jaya Lestari</span>
        </div> -->

        <nav v-if="isLoggedIn" class="navbar navbar-expand-sm bg-primary navbar-dark p-2 d-md-none d-flex">
          <!-- <div class="container-fluid"> -->
          <div class="container">
            <a class="navbar-brand" href="#">
              <img src="./assets/icon-logo-white.png" alt="Mantapps" style="width:40px;">
            </a>
            
            <a href="#" class="text-white" data-bs-toggle="offcanvas" data-bs-target="#bdSidebar">
              <i class="fa-solid fa-bars"></i>
            </a>
          </div>
        </nav>

        <!-- body -->
        <!-- <div class="p-4">
          <nav style="--bs-breadcrumb-divider:'>';font-size:14px">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <i class="fa-solid fa-house"></i>
              </li>
              <li class="breadcrumb-item">Learning Content</li>
              <li class="breadcrumb-item">Next Page</li>
            </ol>
          </nav>

          <hr>
          <div class="row">
            <div class="col">
              <p>Page content goes here</p>
            </div>
          </div>
        </div> -->

        <router-view></router-view>

      </div>
    </div>

    <!-- <img alt="Vue logo" src="./assets/logo.png">
    <HelloWorld msg="Welcome to Your Vue.js App"/> -->
    <!-- <BottomMenu></BottomMenu> -->
  </div>
</template>

<script>
  import Vue from 'vue';
  import VueSweetalert2 from 'vue-sweetalert2';
  import 'sweetalert2/dist/sweetalert2.min.css';
  import SweetModal from 'sweet-modal-vue/src/plugin.js';
  // import HelloWorld from './components/HelloWorld.vue'
  // import BottomMenu from './components/BottomMenu.vue';
  // import HeaderMenu from './components/HeaderMenu.vue';
  import ElementUI from 'element-ui';
  import 'element-ui/lib/theme-chalk/index.css';
  import 'element-ui/lib/theme-chalk/index.css';
  import locale from 'element-ui/lib/locale/lang/en';
  import AsideMenu from '../src/components/version01Element/Asidebar.vue';
  // Use the plugin
  Vue.use(ElementUI, {
    locale
  });
  Vue.use(VueSweetalert2);
  Vue.use(SweetModal);
  // Vue.use(ElementUI);
  export default {
    name: 'App',
    components: {
      // HelloWorld
      // BottomMenu,
      // HeaderMenu,
      AsideMenu
    },
    computed: {
      isLoggedIn() {
        // return !!sessionStorage.getItem('authToken');
        return !!sessionStorage.getItem('authToken');
      }
    },
  }
</script>

<style lang="scss" scoped>
  @import '@/scss/_theme.scss';

  @font-face {
    font-family: 'PlusJakartaSans';
    src: url('@/assets/fonts/plus-jakarta-sans/PlusJakartaSans-VariableFont_wght.ttf') format('truetype');
    /* Tambahkan format lain jika diperlukan */
  }

  #app {
    /* font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
    color: #2c3e50;
    font-family: 'PlusJakartaSans', sans-serif !important;
    // background-color: #F7F7F8;
    // max-width: 480px;
    // min-height: 100vh;
    // padding-bottom: 1px;
    // margin-top: 0;
    // margin: auto;
    // box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  }



  /* Media query untuk perangkat mobile */
  // @media screen and (max-width: 576px) {
  //   #app {
  //     /* Gaya khusus untuk perangkat mobile */
  //     // margin: auto;
  //     // margin-top: 0;
  //     // max-width: 480px;
  //     min-height: 100vh;
  //     // padding-bottom: 1px;
  //     // box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  //   }
  // }

  // /* Media query untuk desktop */
  // @media screen and (min-width: 1024px) {
  //   #app {
  //     /* Gaya khusus untuk tablet */
  //     min-height: 100vh;
  //   }
  // }

  /* Media query untuk tablet */
  // @media screen and (min-width: 769px) and (max-width: 1024px) {
  //   #app {
  //     /* Gaya khusus untuk tablet */
  //     min-height: 100vh;
  //   }
  // }
</style>

<style>
  .tombol-custom {
    width: 100%;
    height: auto;
    color: white;
    text-decoration: none;
    padding: 10px;
    text-align: center;
  }
</style>
<!-- button -->