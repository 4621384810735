// routes.js
import layout from '../views/00/LayoutPage.vue'

const loadView = (view) => {
  return () => import(`../views/${view}.vue`);
};

export default [
  {
    path: '/',
    // redirect: { name: 'LoginPage' }, // Tentukan halaman default di sini
    component: loadView('Login/IndexPage'),
  },
  {
    path: '/penyesuaian',
    name: 'PenyesuaianPage',
    meta: {value: 'Penyesuaian'},
    component: layout,
    children: [
      {
        path: '',
        name: 'PenyesuaianIndex',
        meta: {value: 'Index'},
        component: loadView('Penyesuaian/IndexPage')
      },
      {
        path: '/penyesuaian/create',
        name: 'PenyesuaianCreate',
        meta: {value: 'Create'},
        component: loadView('Penyesuaian/CreatePage')
      },
    ]
  },
  {
    path: '/stok-masuk',
    name: 'StokMasukPage',
    meta: {value: 'Stok Masuk'},
    component: layout,
    children: [
      {
        path: '',
        name: 'StokMasukIndex',
        meta: {value: 'Index'},
        component: loadView('StokMasuk/IndexPage')
      },
      {
        path: '/stok-masuk/create',
        name: 'StokMasukCreate',
        meta: {value: 'Create'},
        component: loadView('StokMasuk/CreatePage')
      },
    ]
  },
  {
    path: '/stok-keluar',
    name: 'StokKeluarPage',
    meta: {value: 'Stok Keluar'},
    component: layout,
    children: [
      {
        path: '',
        name: 'StokKeluarIndex',
        meta: {value: 'Index'},
        component: loadView('StokKeluar/IndexPage')
      },
      {
        path: '/stok-keluar/create',
        name: 'StokKeluarCreate',
        meta: {value: 'Create'},
        component: loadView('StokKeluar/CreatePage')
      },
    ]
  },
  {
    path: '/setting',
    name: 'SettingAkunPage',
    meta: {value: 'Setting'},
    component: layout,
    // component: loadView('SettingAkun/IndexPage'),
    children: [
      {
        path: '',
        name: 'SettingAkunIndex',
        meta: {value: 'Setting Akun'},
        component: loadView('SettingAkun/IndexPage')
      },
    ]
  },
  {
    path: '/list-barang',
    name: 'ListBarangPage',
    meta: {value: 'List Barang'},
    component: layout,
    // component: loadView('SettingAkun/IndexPage'),
    children: [
      {
        path: '',
        name: 'ListBarangIndex',
        meta: {value: 'List Barang'},
        component: loadView('ListBarang/IndexPage')
      },
    ]
  },
  {
    path: '/order-detail/',
    name: 'OrderDetailPage',
    component: loadView('OrderDetail/IndexPage'),
  },
  // {
  //   path: '/order/',
  //   name: 'OrderPage',
  //   component: loadView('Order/IndexPage'),
  // },
  {
    path: '/order/',
    name: 'OrderPage',
    meta: {value: 'Order'},
    component: layout,
    children: [
      {
        path: '',
        name: 'OrderIndex',
        meta: {value: 'Index'},
        component: loadView('Order/IndexPage')
      },
      {
        path: '/order/store',
        name: 'OrderStore',
        component: loadView('Order/process/StorePage')
      },
      {
        path: '/temp-order/store',
        name: 'TempOrderStore',
        component: loadView('Order/process/StorePage')
      },
      {
        path: '/temp-order/:id/delete',
        name: 'TempOrderDelete',
        component: loadView('Order/process/DeletePage'),
      },
    ]
  },
  {
    path: '/pembayaran/',
    name: 'PembayaranPage',
    component: loadView('Pembayaran/IndexPage'),
  },
  // pelanggan
  {
    path: '/pelanggan/',
    name: 'PelangganPage',
    meta: {value: 'Pelanggan'},
    component: layout,
    children: [
      {
        path: '',
        name: 'PelangganIndex',
        meta: {value: 'Index'},
        component: loadView('Pelanggan/IndexPage')
      },
      {
        path: '/pelanggan/create',
        name: 'PelangganCreate',
        meta: {value: 'Create'},
        component: loadView('Pelanggan/CreatePage')
      },
      {
        path: '/pelanggan/store',
        name: 'PelangganStore',
        component: loadView('Pelanggan/process/StorePage'), 
      },
      {
        path: '/pelanggan/:id/show',
        name: 'PelangganShow',
        meta: {value: 'Show'},
        component: loadView('Pelanggan/ShowPage')
      },
      {
        path: '/pelanggan/edit',
        name: 'PelangganEdit',
        meta: {value: 'Edit'},
        component: loadView('Pelanggan/CreatePage')
      },
      {
        path: '/pelanggan/:id/update',
        name: 'PelangganUpdate',
        component: loadView('Pelanggan/process/UpdatePage'),
      },
      {
        path: '/pelanggan/:id/delete',
        name: 'PelangganDelete',
        component: loadView('Pelanggan/process/DeletePage'),
      },
    ]
    // component: loadView('Pelanggan/IndexPage'),
  },

  {
    path: '/metode-pembayaran/',
    name: 'MetodePembayaranPage',
    component: loadView('MetodePembayaran/IndexPage'),
  },
  {
    path: '/barang-harga/',
    name: 'BarangHargaPage',
    meta: {value: 'Harga Barang'},
    component: layout,
    children: [
      {
        path: '',
        name: 'BarangHargaIndex',
        meta: {value: 'Index'},
        component: loadView('BarangHarga/IndexPage')
      },
      {
        path: '/barang-harga/create',
        name: 'BarangHargaCreate',
        meta: {value: 'Create'},
        component: loadView('BarangHarga/CreatePage')
      },
      {
        path: '/barang-harga/store',
        name: 'BarangHargaStore',
        component: loadView('BarangHarga/process/StorePage')
      },
      {
        path: '/barang-harga/:id/show',
        name: 'BarangHargaShow',
        meta: {value: 'Show'},
        component: loadView('BarangHarga/ShowPage')
      },
      {
        path: '/barang-harga/:id/update',
        name: 'BarangHargaUpdate',
        component: loadView('BarangHarga/process/UpdatePage')
      },
      {
        path: '/barang-harga/:id/delete',
        name: 'BarangHargaDelete',
        component: loadView('BarangHarga/process/DeletePage')
      },
    ]
    // component: loadView('BarangHarga/IndexPage'),
  },
  {
    path: '/barang-master',
    meta: {value: 'Barang Master'},
    name: 'BarangMasterPage',
    component: layout,
    children: [
      {
        path: '',
        name: 'BarangMasterIndex',
        meta: {value: 'Index'},
        component: loadView('BarangMaster/IndexPage')
      },
      {
        path: '/barang-master/create',
        name: 'BarangMasterCreate',
        meta: {value: 'Create'},
        component: loadView('BarangMaster/CreatePage')
      },
      {
        path: '/barang-master/:id/show',
        name: 'BarangMasterShow',
        meta: {value: 'Detail'},
        component: loadView('BarangMaster/ShowPage')
      },
      {
        path: '/barang-master/store',
        name: 'BarangMasterStore',
        component: loadView('BarangMaster/process/StorePage'), 
      },
      {
        path: '/barang-master/edit',
        name: 'BarangMasterEdit',
        meta: {value: 'Edit'},
        component: loadView('BarangMaster/CreatePage')
      },
      {
        path: '/barang-master/:id/update',
        name: 'BarangMasterUpdate',
        component: loadView('BarangMaster/process/UpdatePage'),
      },
      {
        path: '/barang-master/:id/delete',
        name: 'BarangMasterDelete',
        component: loadView('BarangMaster/process/DeletePage'),
      },
    ]
  }, // barang-master
  {
    path: '/barang-stok',
    meta: {value: 'Barang Stok'},
    name: 'BarangMasterPage',
    component: layout,
    children: [
      {
        path: '',
        name: 'BarangStokIndex',
        meta: {value: 'Index'},
        component: loadView('BarangStok/IndexPage')
      },
      {
        path: '/barang-stok/create',
        name: 'BarangStokCreate',
        meta: {value: 'Create'},
        component: loadView('BarangStok/CreatePage')
      },
      {
        path: '/barang-stok/:id/show',
        name: 'BarangStokShow',
        meta: {value: 'Detail'},
        component: loadView('BarangStok/ShowPage')
      },
      {
        path: '/barang-stok/store',
        name: 'BarangStokStore',
        component: loadView('BarangStok/process/StorePage'), 
      },
      // {
      //   path: '/barang-master/edit',
      //   name: 'BarangMasterEdit',
      //   meta: {value: 'Edit'},
      //   component: loadView('BarangMaster/CreatePage')
      // },
      {
        path: '/barang-stok/:id/update',
        name: 'BarangStokUpdate',
        component: loadView('BarangStok/process/UpdatePage'),
      },
      {
        path: '/barang-stok/:id/delete',
        name: 'BarangStokDelete',
        component: loadView('BarangStok/process/DeletePage'),
      },
    ]
  }, // barang-stok
  {
    path: '/supplier/',
    meta: {value: 'Supplier'},
    name: 'SupplierPage',
    component: layout,
    children: [
      {
        path: '',
        name: 'SupplierIndex',
        meta: {value: 'Index'},
        component: loadView('Supplier/IndexPage')
      },
      {
        path: '/supplier/create/',
        name: 'SupplierCreate',
        meta: {value: 'Create'},
        component: loadView('Supplier/CreatePage')
      },
      {
        path: '/supplier/store',
        name: 'SupplierStore',
        component: loadView('Supplier/process/StorePage'), 
      },
      {
        path: '/supplier/:id/show',
        name: 'SupplierShow',
        meta: {value: 'Detail'},
        component: loadView('Supplier/ShowPage')
      },
      {
        path: '/supplier/edit',
        name: 'SupplierEdit',
        meta: {value: 'Edit'},
        component: loadView('Supplier/CreatePage')
      },
      {
        path: '/supplier/:id/update',
        name: 'SupplierUpdate',
        component: loadView('Supplier/process/UpdatePage'),
      },
      {
        path: '/supplier/:id/delete',
        name: 'SupplierDelete',
        component: loadView('Supplier/process/DeletePage'),
      },
    ]
  }, // supplier
  {
    path: '/dashboard',
    name: 'Home',
    meta: {value: 'Home'},
    component: loadView('Home-new'),
  },
  
  {
    path: '/test',
    name: 'Prototype00Page',
    component: loadView('00/Prototype00Page'),
  },
  {
    path: '/login',
    name: 'LoginPage',
    component: layout,
    children: [
      {
        path: '',
        name: 'LoginPageIndex',
        component: loadView('Login/IndexPage')
      },
      {
        path: '/login/process',
        name: 'LoginProcess',
        component: loadView('Login/process/LoginPage'), 
      },
      {
        path: '/logout/process',
        name: 'LogoutProcess',
        component: loadView('Login/process/LogoutPage'), 
      },
    ]
  },
  {
    path: '/login-old',
    name: 'LoginOld',
    component: loadView('LoginOld/IndexPage'),
  },
  {
    path: '/register',
    name: 'Register',
    component: loadView('Login/RegisterPage'),
  },
  {
    path: '/form',
    name: 'Form',
    component: loadView('Form/IndexPage'),
  },
  { 
    path: '/dashboard',
    name: 'Dashboard',
    component: loadView('Home'), 
  },
  { 
    path: '/about',
    name: 'About',
    component: loadView('Main/About'), 
  },
  { 
    path: '/contact',
    name: 'ContactUs',
    component: loadView('Main/Contact'), 
  },
  { 
    path: '/member',
    name: 'Member',
    component: loadView('Member/IndexPage'), 
  },
  // fitur barang
  { 
    path: '/barang',
    name: 'Barang',
    component: loadView('Barang/IndexPage'), 
  },
  { 
    path: '/barang/create',
    name: 'BarangCreate',
    component: loadView('Barang/CreatePage'), 
  },
  { 
    path: '/barang/store',
    name: 'BarangStore',
    component: loadView('Barang/StorePage'), 
  },
  {
    path: '/barang/:id/show',
    name: 'BarangShow',
    component: loadView('Barang/ShowPage'),
  },
  {
    path: '/barang/:id/edit',
    name: 'BarangEdit',
    component: loadView('Barang/EditPage'),
  },
  {
    path: '/barang/:id/update',
    // redirect: { name: 'Barang' },
    name: 'BarangUpdate',
    component: loadView('Barang/UpdatePage'),
  },
  {
    path: '/barang/:id/delete',
    // redirect: { name: 'Barang' },
    name: 'BarangDelete',
    component: loadView('Barang/DeletePage'),
  },
  
  // fitur harga barang modal
  { 
    path: '/harga-barang-modal',
    name: 'HargaBarangModal',
    component: loadView('HargaBarangModal/IndexPage'), 
  },
  { 
    path: '/harga-barang-modal/create',
    name: 'HargaBarangModalCreate',
    component: loadView('HargaBarangModal/CreatePage'), 
  },
  { 
    path: '/harga-barang-modal/store',
    name: 'HargaBarangModalStore',
    component: loadView('HargaBarangModal/StorePage'), 
  },
  { 
    path: '/harga-barang-modal/:id/show',
    name: 'HargaBarangModalShow',
    component: loadView('HargaBarangModal/ShowPage'), 
  },
  {
    path: '/harga-barang-modal/:id/update',
    // redirect: { name: 'Barang' },
    name: 'HargaBarangModalUpdate',
    component: loadView('HargaBarangModal/UpdatePage'),
  },
  {
    path: '/harga-barang-modal/:id/delete',
    // redirect: { name: 'Barang' },
    name: 'HargaBarangModalDelete',
    component: loadView('HargaBarangModal/DeletePage'),
  },
  // Barang Satuan
  { 
    path: '/barang-satuan',
    name: 'BarangSatuanPage',
    meta: {value: 'Satuan'},
    component: layout,
    children: [
      {
        path: '',
        name: 'BarangSatuanIndex',
        meta: {value: 'Index'},
        component: loadView('BarangSatuan/IndexPage')
      },
      {
        path: '/barang-satuan/create',
        name: 'BarangSatuanCreate',
        meta: {value: 'Create'},
        component: loadView('BarangSatuan/CreatePage')
      },
      {
        path: '/barang-satuan/store',
        name: 'BarangSatuanStore',
        component: loadView('BarangSatuan/process/StorePage'), 
      },
      {
        path: '/barang-satuan/:id/show',
        name: 'BarangSatuanShow',
        meta: {value: 'Show'},
        component: loadView('BarangSatuan/ShowPage')
      },
      {
        path: '/barang-satuan/edit',
        name: 'BarangSatuanEdit',
        meta: {value: 'Edit'},
        component: loadView('BarangSatuan/CreatePage')
      },
      {
        path: '/barang-satuan/:id/update',
        name: 'BarangSatuanUpdate',
        component: loadView('BarangSatuan/process/UpdatePage'),
      },
      {
        path: '/barang-satuan/:id/delete',
        name: 'BarangSatuanDelete',
        component: loadView('BarangSatuan/process/DeletePage'),
      },
    ]
  },
  // Staff Barang
  { 
    path: '/staff/barang',
    name: 'StaffBarang',
    component: loadView('Staff/IndexPage'), 
  },
];
